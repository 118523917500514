import axios from "axios";
import axiosJsonpAdapter from "axios-jsonp";
import { EXTERNAL_API_URL, PROHIBITED_CHARACTERS_REGEX } from "@/const";
export default {
  data() {
    return {
      validate: {
        collectFactory: [],
      },
      dummyValidateEdit: {
        collectFactory: {
          name: {isValid: true, errMsg: ""},
          addressInfo: {
            zipCode: {isValid: true, errMsg: ""},
            prefectures: {isValid: true, errMsg: ""},
            address: {isValid: true, errMsg: ""},
            buildingName: {isValid: true, errMsg: ""},
          },
          tel: {isValid: true, errMsg: ""},
          fax: {isValid: true, errMsg: ""},
          jwnetContractNo: {isValid: true, errMsg: ""},
          ediKey: {isValid: true, errMsg: ""},
          publicConfirmationNo: {isValid: true, errMsg: ""},
          collectOfficeList: [
            {
              name: { isValid: true, errMsg: "" },
              zipCode: { isValid: true, errMsg: "" },
              prefecturesId: { isValid: true, errMsg: "" },
              prefecturesName: { isValid: true, errMsg: "" },
              address: { isValid: true, errMsg: "" },
              buildingName: { isValid: true, errMsg: "" },
              tel: { isValid: true, errMsg: "" },
              fax: { isValid: true, errMsg: "" },
            },
          ],
        },
        collectOffice: {
          name: {isValid: true, errMsg: ""},
          zipCode: {isValid: true, errMsg: ""},
          prefecturesId: {isValid: true, errMsg: ""},
          prefecturesName: {isValid: true, errMsg: ""},
          address: {isValid: true, errMsg: ""},
          buildingName: {isValid: true, errMsg: ""},
          tel: {isValid: true, errMsg: ""},
          fax: {isValid: true, errMsg: ""},
        },
      },
      isValidForm: {
        collectFactory: true,
      },
    };
  },
  methods: {
    async collectFactoryValidateCheckEdit() {
      let isValidForm = true;
      const halfAlphaRegexp = new RegExp(/^[A-Za-z0-9]*$/);
      for (let i = 0; i < this.validate.collectFactory.length; i++) {
        const v = this.validate.collectFactory[i];
        const targetForm = this.formEdit.collectFactoryEditList[i];
        // 事業者名の禁則文字のチェック
        if (PROHIBITED_CHARACTERS_REGEX.test(targetForm.name)) {
          v.name.errMsg = "登録できない文字が含まれています。";
          isValidForm = false;
        }
        if (!v.name.isValid) {
          v.name.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.addressInfo.zipCode.isValid) {
          v.addressInfo.zipCode.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        } else {
          const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${targetForm.addressInfo.zipCode}`;
          await axios
            .get(url, {adapter: axiosJsonpAdapter})
            .then((res) => {
              const results = res.data.results;
              if (results != null) {
                if (v.addressInfo.prefectures.isValid) {
                  var prefectureFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.selectedPrefectureTemp === address.address1) {
                      prefectureFlg = true;
                    }
                  });
                  if (!prefectureFlg) {
                    v.addressInfo.prefectures.errMsg = "郵便番号と都道府県が一致していません。";
                    isValidForm = false;
                  }
                }
                if (v.addressInfo.address.isValid) {
                  var addressFlg = false;
                  results.forEach((address) => {
                    if (targetForm.addressInfo.address === address.address2) {
                      addressFlg = true;
                    }
                  });
                  if (!addressFlg) {
                    v.addressInfo.address.errMsg = "郵便番号と市区町村が一致していません。";
                    isValidForm = false;
                  }
                }
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
        if (!v.addressInfo.prefectures.isValid) {
          v.addressInfo.prefectures.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.addressInfo.address.isValid) {
          v.addressInfo.address.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.addressInfo.buildingName && !v.addressInfo.buildingName.isValid) {
          v.addressInfo.buildingName.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.tel.isValid) {
          v.tel.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (targetForm.fax && !v.fax.isValid) {
          v.fax.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!v.jwnetContractNo.isValid) {
          v.jwnetContractNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(targetForm.jwnetContractNo)) {
          v.jwnetContractNo.errMsg = "JWNET加入者番号は半角英数字で入力してください。";
          isValidForm = false;
        }
        if (!v.ediKey.isValid) {
          v.ediKey.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }
        if (!halfAlphaRegexp.test(targetForm.ediKey)) {
          v.ediKey.errMsg = "EDI利用確認キーは半角英数字で入力してください。";
          isValidForm = false;
        }
        if (targetForm.publicConfirmationNo && !v.publicConfirmationNo.isValid) {
          v.publicConfirmationNo.errMsg = "正しい値を入力してください。";
          isValidForm = false;
        }

        for (let j = 0; j < v.collectOfficeList.length; j++) {
          const v2 = v.collectOfficeList[j];
          const t2 = targetForm.collectOfficeList[j];

          // 事業者名の禁則文字のチェック
          if (PROHIBITED_CHARACTERS_REGEX.test(t2.name)) {
            v2.name.errMsg = "登録できない文字が含まれています。";
            isValidForm = false;
          }
          if (!t2.name) {
            v2.name.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (!v2.zipCode.isValid) {
            v2.zipCode.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          } else {
            const url = `${EXTERNAL_API_URL.SEARCH_ADDRESS}?zipcode=${t2.addressInfo.zipCode}`;
            await axios
              .get(url, {adapter: axiosJsonpAdapter})
              .then((res) => {
                const results = res.data.results;
                if (results != null) {
                  if (v2.prefecturesName.isValid) {
                    var prefectureFlg = false;
                    results.forEach((address) => {
                      if (t2.addressInfo.selectedPrefectureTemp === address.address1) {
                        prefectureFlg = true;
                      }
                    });
                    if (!prefectureFlg) {
                      v2.prefecturesName.errMsg = "郵便番号と都道府県が一致していません。";
                      isValidForm = false;
                    }
                  }
                  if (v2.address.isValid) {
                    var addressFlg = false;
                    results.forEach((address) => {
                      if (t2.addressInfo.address === address.address2) {
                        addressFlg = true;
                      }
                    });
                    if (!addressFlg) {
                      v2.address.errMsg = "郵便番号と市区町村が一致していません。";
                      isValidForm = false;
                    }
                  }
                }
              })
              .catch((err) => {
                console.log(err);
              });
          }
          if (!v2.prefecturesName.isValid) {
            v2.prefectures.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (!v2.address.isValid) {
            v2.address.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (t2.addressInfo.buildingName && !v2.buildingName.isValid) {
            v2.buildingName.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (!v2.tel.isValid) {
            v.tel.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
          if (t2.fax && !v2.fax.isValid) {
            v2.fax.errMsg = "正しい値を入力してください。";
            isValidForm = false;
          }
        }
      }
      return isValidForm;
    },
    async runValidateEdit() {
      let isValidForm = false;
      this.isValidForm.collectFactory = await this.collectFactoryValidateCheckEdit();
      for (let k in this.isValidForm) {
        if (this.isValidForm[k]) {
          isValidForm = true;
        } else {
          isValidForm = false;
          break;
        }
      }

      return isValidForm;
    },
  },
};
