import axios from "axios";
import {API_URL} from "@/const";
import formValidationEdit from "../mixins/form/formValidationEdit";

export default {
  mixins: [
    formValidationEdit,
  ],

  data() {
    return {
      prefectures: [],
      duplicatedNameInfoList: [], //重複する名称情報
    };
  },
  methods: {
    // 運搬事業所情報取得API
    getCollectFactoryListApi(collectorId) {
      return axios
        .get(API_URL.COLLECTOR.DETAIL + collectorId)
        .then((res) => {
          const factories = res.data.collectFactoryList;
          this.formEdit.collectFactoryEditList = [];
          this.validate.collectFactory = [];
          factories.forEach(factory => {
            const newFactory = {
              id: factory.id,
              name: factory.name,
              addressInfo: {
                zipCode: factory.addressInfo.zipCode,
                selectedPrefectureTemp: factory.addressInfo.prefecturesName,
                prefecturesId: factory.addressInfo.prefecturesId,
                address: factory.addressInfo.address,
                buildingName: factory.addressInfo.buildingName,
              },
              tel: factory.tel,
              fax: factory.fax,
              jwnetContractNo: factory.jwnetContractNo,
              ediKey: factory.ediKey,
              publicConfirmationNo: factory.publicConfirmationNo,
              collectOfficeList: [],
            };
            const newFactoryValidation = JSON.parse(JSON.stringify(this.dummyValidateEdit.collectFactory));

            newFactoryValidation.collectOfficeList = [];
            factory.collectOfficeList.forEach(office => {
              const newOffice = {
                id: office.id,
                name: office.name,
                addressInfo: {
                  zipCode: office.zipCode,
                  selectedPrefectureTemp: office.prefecturesName,
                  prefecturesId: office.prefecturesId,
                  address: office.address,
                  buildingName: office.buildingName,
                },
                tel: office.tel,
                fax: office.fax,
              };
              newFactory.collectOfficeList.push(newOffice);
              newFactoryValidation.collectOfficeList.push(JSON.parse(JSON.stringify(this.dummyValidateEdit.collectOffice)));
            });

            this.formEdit.collectFactoryEditList.push(newFactory);
            this.validate.collectFactory.push(newFactoryValidation);
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // 都道府県選択肢取得API   DOMAIN.PREFECTURES
    getPrefecturesApi() {
      return axios
        .get(API_URL.DOMAIN.PREFECTURES)
        .then((res) => {
          this.prefectures = res.data.prefectures;
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 運搬事業者情報取得API
    getCollectorApi(collectorId) {
      return axios
        .get(API_URL.COLLECTOR.DETAIL + collectorId)
        .then((res) => {
          this.tempData = {
            name: res.data.collectorBaseInfo.name,
            addressInfo: {
              zipCode: res.data.collectorBaseInfo.zipCode,
              prefecturesId: res.data.collectorBaseInfo.prefectures.id,
              selectedPrefectureTemp: res.data.collectorBaseInfo.prefectures.name,
              address: res.data.collectorBaseInfo.address,
              buildingName: res.data.collectorBaseInfo.buildingName,
            },
            tel: res.data.collectorBaseInfo.tel,
            fax: res.data.collectorBaseInfo.fax,
          };
        })
        .catch((err) => {
          console.log(err);
        });
    },

    // 所属による名称が重複チェックAPI
    checkDuplicationName(duplicationNameCheckInfoList) {
      return axios
          .post(API_URL.DUPLICATION.NAME, {duplicationNameCheckInfoList : duplicationNameCheckInfoList})
          .then((res) => {
            this.duplicatedNameInfoList = res.data.duplicatedNameInfoList;
          })
          .catch((err) => {
            console.log(err);
          });
    },
  },
};
